<template>
  <div class="main-content">
    <div class="register">
      <div class="top_line"></div>
      <div class="register_main">
        <div class="title">注册新用户</div>
        <div>
          <a-input placeholder="请输入邮箱" class="input" v-model="user.username"/>
        </div>
        <div>
          <a-input type="password" placeholder="请输入密码" class="input" v-model="user.password"/>
        </div>
        <div>
          <a-input type="password" placeholder="请重新输入密码" class="input" v-model="user.repassword"/>
        </div>
        <div>
          <a-checkbox v-model="isagree"  @change="agreeonChange">
            同意 <a @click="showUserService">《服务条款》</a>，<a @click="showPrivacyPolicy">《隐私政策》</a>
          </a-checkbox>
        </div>
        <div>
          <a-button block type="danger"  @click="doreg" :disabled="this.isagree === false">
            注 册
          </a-button>
        </div>
        <div class="logins">
          <div>忘记密码？</div>
          <div><router-link to="/login">已有账户</router-link></div>
        </div>
      </div>

    </div>
    <a-modal :visible="UserService" title="用户服务协议" width="85%" @cancel="handleCancel">
      <articletextonly :article_id="291"/>
    </a-modal>
    <a-modal :visible="PrivacyPolicy" title="隐私条款" width="85%" @cancel="handleCancel">
      <articletextonly :article_id="292"/>
    </a-modal>
  </div>
</template>

<script>
import Storage from "../common/storage";
import Config from "../config";
import articletextonly from "@/views/articletextonly";

export default {
  components: {articletextonly},
  data() {
    return {
      server_url: Config.data.server_url,
      loading: false,
      user: {
        username: "",
        name: "",
        mobile: "",
        sex: "",
        email: "",
        photo: "",
        password: "",
        repassword: "",
      },
      isagree: false,
      UserService: false,
      PrivacyPolicy: false,
    }
  },
  created() {
    // 获取头部菜单内容
  },

  methods: {

    agreeonChange(e) {
      console.log(`checked = ${e.target.checked}`);
      this.isagree = e.target.checked
    },
    showUserService() {

      this.UserService = true
    },
    showPrivacyPolicy() {
      this.PrivacyPolicy = true
    },
    handleCancel() {
      this.PrivacyPolicy = false;
      this.UserService = false;
    },
    async doreg() {
      this.loading = true;
      if (this.user.username === ""){
        this.$message.error('请输入用户名');
        return;
      }
      if (this.user.password === "" ||  this.user.repassword === ""){
        this.$message.error('请输入密码或重复密码');
        return;
      }
      if (this.user.password !== this.user.repassword ){
        this.$message.error('两次密码不一致');
          return;
      }
      if (this.isagree!==true) {
        this.$message.error('请阅读并同意服务条款');
        return;
      }

      let result = await this.$post("/api/customer/register", {
        save_value: {
          login_name: this.user.username,
          name: this.user.name,
          mobile: this.user.mobile,
          sex: this.user.sex,
          email: this.user.email,
          photo: this.user.photo,
          password: this.user.password,
        }
      });

      if (result.status === true) {
        console.log("result", result.data);
        Storage.uid = result.data.uid;
        Storage.token = result.data.token;
        Storage.login_name = result.data.email;

        // Storage.myName = result.data.name;
        Storage.token_timeout = result.data.token_timeout;
        Storage.is_check_email = -1;
        // if (this.isagree===true) {
        //   Storage.account = result.data.login_name;
        // } else {
        //   Storage.account = "";
        // }
        this.$router.push({path: "/"});
      }

      this.loading = false;
    },

  }
}
</script>
<style lang="scss" scoped>
.main-content {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url('../static/images/reg_bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.register {
  width: 400px;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  .register_main {
    padding: 40px;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
  }

  .top_line {
    height: 8px;
    background: #002654;
    border-radius: 8px 8px 0px 0px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    line-height: 45px;
    margin-bottom: 40px;
    text-align: center;
  }
  .input{
    background: #F7F9FA;
    border-radius: 24px 24px 24px 24px;
    height: 44px;
    opacity: 1;
    border: 1px solid #E6EAEB;
    margin-bottom: 24px;
  }
}
.logins{
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}
/deep/ .ant-btn-danger {
  height: 44px;
  margin-top: 24px;
  border-radius : 22px;
  color: #fff;
  background-color: #CE1126 ;
  border-color: #CE1126 ;
}
/deep/ .ant-btn-danger[disabled] {
  height: 44px;
  margin-top: 24px;
  border-radius : 22px;
  color: #222;
  background-color: #f5f5f5 ;
  border-color: #d9d9d9 ;
}
</style>
