/**
 * 仓库
 */
export default {
    set uid(value) {
        localStorage.setItem('uid', value)
    },

    get uid() {
        return localStorage.getItem('uid')
    },
    set token(value) {
        localStorage.setItem('token', value)
    },

    get token() {
        return localStorage.getItem('token')
    },
    set token_timeout(value) {
        localStorage.setItem('token_timeout', value)
    },

    get token_timeout() {
        return localStorage.getItem('token_timeout')
    },
    set login_name(value) {
        localStorage.setItem('login_name', value)
    },

    get login_name() {
        return localStorage.getItem('login_name')
    },
    set account(value) {
        localStorage.setItem('account', value)
    },

    get account() {
        return localStorage.getItem('account')
    },
    set is_check_email(value) {
        localStorage.setItem('is_check_email', value)
    },

    get is_check_email() {
        return localStorage.getItem('is_check_email')
    },
    set sex(value) {
        localStorage.setItem('sex', value)
    },

    get sex() {
        return localStorage.getItem('sex')
    },
    set mobile(value) {
        localStorage.setItem('mobile', value)
    },

    get mobile() {
        return localStorage.getItem('mobile')
    },
    set name(value) {
        localStorage.setItem('name', value)
    },

    get name() {
        return localStorage.getItem('name')
    },
}
