<template>
  <div>
    <div class="article_info ql-container ql-snow">
      <div class="tilte"> {{ articleData.title }}</div>
      <div class="content">
        <div v-if="articleData.type===1||articleData.type===4" class="ql-editor article_content article_info_content"
             v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>

import Config from "@/config";

export default {
  components: {},
  name: "category_list",
  props: ['article_id'],
  data() {
    return {
      medialink: "",
      server_url: Config.data.server_url,
      server_url1: Config.data.server_url1,
      categoryinfoData: "",
      language: localStorage.language,
      show_id: "",
      content: "",
      short_content: "",
      categoryData: [],
      articleData: [],
      current: 1,
      videoState: true,
      video_pay: false,
      loading: false,
      videoOptions: {
        autoplay: true,

        controls: true,
        sources: [
          {
            src: "",
            type: "video/mp4"
          }
        ]
      },
      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 1,
      },
    }
  },
  created() {

    console.log(this.$route.query.articleid)
    // this.article_id = this.$route.query.articleid
    Promise.all([this.getarticleinfo(this.article_id)]).then(() => {

      this.loading = false;
    }).catch((error) => {
      console.log(error)
    })


  },
  mounted() {

  },
  methods: {


    async getarticleinfo(article_id) {
      console.log("请求id", article_id);
      this.articleData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/article/info", {
        params: {
          article_id: article_id,
          language: this.language,
        }
      });

      if (result.status === true) {
        this.articleData = result.data;
        this.content = JSON.parse(result.data.content);
        this.short_content = JSON.parse(result.data.short_content);
        console.log("result", result.data);
        if (result.data.more_info_json.medialink) {
          // this.medialink = result.data.more_info_json.medialink;
          this.mediainfo(result.data.more_info_json.medialink)
          this.mediatitle = result.data.more_info_json.mediatitle;
        }

        this.getcategoryinfo(result.data.articles_category_id)
        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    async onChange(pagination) {
      console.log(pagination.current)
    },

  }
}
</script>

<style lang="scss" scoped>
.top_head {
  max-width: 1920px !important;
  margin-left: auto;
  margin-right: auto;
  height: 560px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

}

.breadcrumb_bg {
  background: #F2F4F5;
  padding-top: 18px;
  height: 64px;
}

.breadcrumb {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;

}

.title {
  color: #fff;
}

.active {
  color: #fff;
  background: #CE1126;
}

.article_info {
  //max-width: 1248px;
  //margin-bottom: 40px;
  //margin-right: auto;
  //margin-left: auto;
  .tilte {
    //margin-top: 80px;
    text-align: center;
    margin-bottom: 17px;
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }

  .top_img {
    max-height: 450px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto !important;
    }
  }

  .article_info_content {
    /deep/ img {
      max-width: 1248px;
      height: auto
    }
  }

  .article_content {
    margin-top: 34px;
    font-size: 14px;
    line-height: 32px;
    text-indent: 2em;
    margin-bottom: 100px;
  }
}

.content {
  position: relative;

  height: 650px;
  padding: 10px;
  padding-bottom: 0px;
  //overflow-y: scroll;
}
</style>